<template>
  <div>
    <load-profile></load-profile>
    <b-card v-if="data.id" class="attendance">
      <b-table-simple small striped borderless class="mb-2">
        <b-tbody>
          <b-tr>
            <b-th> {{ $t("field.region") }}: </b-th>
            <b-td>
              {{ data.regionCode }}
            </b-td>
            <b-th> {{ $t("field.salesperson") }}: </b-th>
            <b-td>
              {{ data.salespersonName }}
              <br />
              <small class="text-primary">{{ data.salespersonIdCard }}</small>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> {{ $t("field.purpose") }}: </b-th>
            <b-td>
              {{ $t(`purpose.${data.purpose}`) }}
              <div v-if="data.purpose == purpose.visitCallPlan && data.outlet">
                <small class="text-primary">
                  {{ data.outletCode }} -
                  {{ trans(data, "outletName", $i18n.locale) }}
                </small>
              </div>
              <div
                v-if="
                  data.purpose == purpose.supportSales &&
                  data.supportSalesperson
                "
              >
                <small class="text-primary">
                  {{ data.supportSalespersonIdCard }} -
                  {{ data.supportSalespersonName }}
                </small>
              </div>
            </b-td>
            <b-th> {{ $t("field.remark") }}: </b-th>
            <b-td>
              {{ data.remark }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> {{ $t("field.distance") }}: </b-th>
            <b-td>
              <span v-if="data.distance">
                {{ Math.round(data.distance) }} {{ $t("field.metter") }}
              </span>
              <span v-else> - </span>
            </b-td>
            <b-th> {{ $t("field.duration") }}: </b-th>
            <b-td>
              <span v-if="data.duration">
                {{ toMinutesSeconds(data.duration) }}
              </span>
              <span v-else> - </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-row align-h="around">
        <b-col md="5">
          <div class="attendance-image">
            <b-img
              class="cursor-pointer"
              :src="data.checkinImage"
              alt="checkin"
              fluid
              @click="previewImage(data.checkinImage)"
              v-if="data.checkinImage"
            />
            <b-img
              src="@/assets/images/other/default-image.png"
              alt="checkout"
              fluid
              v-else
            />
          </div>
          <div class="mt-2">
            <h4 class="text-success">{{ $t("field.checkin") }}</h4>
            <dl class="row">
              <dt class="col col-4">{{ $t("field.time") }}:</dt>
              <dd class="col text-right">
                {{ data.checkinTime | formatDate }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="col col-4">{{ $t("field.address") }}:</dt>
              <dd class="col text-right">
                <b-link
                  class="link-underline"
                  :href="`https://www.google.com/maps/?q=${data.checkinLatitude},${data.checkinLongitude}`"
                  target="_blank"
                >
                  {{ data.checkinAddress }}
                </b-link>
              </dd>
            </dl>
          </div>
        </b-col>
        <b-col md="5">
          <div class="attendance-image">
            <b-img
              class="cursor-pointer"
              :src="data.checkoutImage"
              alt="checkout"
              fluid
              @click="previewImage(data.checkoutImage)"
              v-if="data.checkoutImage"
            />
            <b-img
              src="@/assets/images/other/default-image.png"
              alt="checkout"
              fluid
              v-else
            />
          </div>
          <div class="mt-2">
            <h4 class="text-danger">{{ $t("field.checkout") }}</h4>
            <dl class="row">
              <dt class="col col-4">{{ $t("field.time") }}:</dt>
              <dd class="col text-right">
                <span v-if="data.checkoutTime">
                  {{ data.checkoutTime | formatDate }}
                </span>
                <span v-else> - </span>
              </dd>
            </dl>
            <dl class="row">
              <dt class="col col-4">{{ $t("field.address") }}:</dt>
              <dd class="col text-right">
                <b-link
                  class="link-underline"
                  :href="`https://www.google.com/maps/?q=${data.checkoutLatitude},${data.checkoutLongitude}`"
                  target="_blank"
                  v-if="data.checkoutLatitude && data.checkoutLongitude"
                >
                  {{ data.checkoutAddress }}
                </b-link>
                <span v-else> - </span>
              </dd>
            </dl>
          </div>
        </b-col>
      </b-row>

      <!-- <GmapMap
        :center="{
          lat: Number(data.checkinLatitude),
          lng: Number(data.checkinLongitude),
        }"
        :zoom="20"
        map-style-id="roadmap"
        :options="mapOptions"
        style="width: 100%; height: 50vmin"
        ref="map"
      >
        <GmapMarker
          :position="{
            lat: Number(data.checkinLatitude),
            lng: Number(data.checkinLongitude),
          }"
          :clickable="false"
          :draggable="false"
          :label="{ text: 'In' }"
          :icon="{
            url: require('@/assets/images/icons/inmarker.svg'),
            scaledSize: { width: 64, height: 64 },
            anchor: { x: 64, y: 32 },
          }"
        />

        <GmapMarker
          v-if="data.checkoutLatitude && data.checkoutLongitude"
          :position="{
            lat: Number(data.checkoutLatitude),
            lng: Number(data.checkoutLongitude),
          }"
          :clickable="false"
          :draggable="false"
          :label="{ text: 'Out' }"
          :icon="{
            url: require('@/assets/images/icons/outmarker.svg'),
            scaledSize: { width: 64, height: 64 },
            anchor: { x: 64, y: 32 },
          }"
        />
      </GmapMap> -->

      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BLink,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import purpose from "@/data/purpose";

const ActivityRepository = Repository.get("activity");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BLink,
    BButton,
  },
  data() {
    return {
      data: {},
      mapOptions: {
        disableDefaultUI: true,
      },
      map: null,

      purpose,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    previewImage(image) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [image],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    moveTo(lat, lng) {
      this.$refs.map.panTo({ lat: Number(lat), lng: Number(lng) });
    },
    show() {
      ActivityRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };
        }
      });
    },
    toMinutesSeconds(totalSeconds) {
      const seconds = Math.floor(totalSeconds % 60);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      const secondsStr = this.makeHumanReadable(seconds, "second");
      const minutesStr = this.makeHumanReadable(minutes, "minute");
      const hoursStr = this.makeHumanReadable(hours, "hour");
      const string = `${hoursStr}${minutesStr}${secondsStr}`.replace(
        /,\s*$/,
        ""
      );

      return string ? string : `0 ${this.$tc("time.second", 0)}`;
    },
    makeHumanReadable(num, singular) {
      return num > 0 ? `${num} ${this.$tc(`time.${singular}`, num)} ` : "";
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "activity";
    const route = "activity";

    return { resource, route };
  },
};
</script>
